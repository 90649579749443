import React from "react"

import Layout from "../components/center-layout"
import SEO from "../components/seo"

/**
 * Page component.
 */
export default class NotFoundPage extends React.Component {

	/**
	 * Renders the component.
	 */
	render(): JSX.Element {
		return (
			<Layout>
				<SEO title="404: Not found" />
				<h1>Not Found</h1>
				<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
			</Layout>
		)
	}
}
